import { Icon } from '@wla/components/ui/icon';
import { cn } from '@wla/lib/helpers/cn';
import { ReactNode } from 'react';
import { JyskIconsId } from '@jysk/icons/font/jysk-icons-ts';

export type AlertTypes = 'error' | 'warning' | 'info' | 'success';

type AlertProps = {
  children: ReactNode;
  type?: AlertTypes;
  className?: string;
};

export function Alert({ children, type = 'error', className }: AlertProps) {
  const iconId = {
    error: 'w3-error',
    warning: 'w3-warning',
    info: 'w3-info',
    success: 'w3-success',
  }[type] as JyskIconsId;

  return (
    <div
      role="alert"
      className={cn(
        'items-center rounded px-5 py-2.5',
        {
          'bg-red-100': type === 'error',
          'bg-yellow-100': type === 'warning',
          'bg-blue-200': type === 'info',
          'bg-green-100': type === 'success',
        },
        className,
      )}
    >
      <div className="flex items-center gap-2.5">
        <Icon src={iconId} size="20" />
        <span>{children}</span>
      </div>
    </div>
  );
}
