import { JYSK_ICONS_CODEPOINTS, JyskIconsId } from '@jysk/icons/font/jysk-icons-ts';
import { cn } from '@wla/lib/helpers/cn';

const IconSizes = {
  '10': 'text-xs',
  '12': 'text-sm',
  '14': 'text-base',
  '16': 'text-lg',
  '18': 'text-xl',
  '20': 'text-2xl',
  '24': 'text-3xl',
  '30': 'text-[30px]',
  '32': 'text-4xl',
  '40': 'text-5xl',
  '48': 'text-6xl',
};

export type IconSize = keyof typeof IconSizes;

export type IconName = JyskIconsId;

type IconProps = {
  src?: IconName;
  className?: string;
  size?: IconSize;
};

export function Icon({ src, size = '18', className, ...rest }: IconProps) {
  if (!src) {
    return null;
  }

  // Find and use unicode character instead of using a css file with all the classnames.
  const uniCodeNumber = JYSK_ICONS_CODEPOINTS[src];
  const uniCode = String.fromCharCode(Number(uniCodeNumber));

  // inline-flex is added to enforce the same height as it's psuedo element
  // suppressHydrationWarning needed to avoid hydration error whenever the icon is used inside a button
  return (
    <span
      {...rest}
      suppressHydrationWarning
      className={cn(`inline-flex font-icon leading-none`, IconSizes[size], className)}
    >
      {uniCode}
    </span>
  );
}
