export type JyskIconsId =
  | "360-marker"
  | "add-store"
  | "add-to-basket"
  | "add-user"
  | "add"
  | "administration"
  | "approvedwebshop"
  | "arrow-down"
  | "arrow-left"
  | "arrow-right"
  | "arrow-up-round"
  | "arrow-up"
  | "b2b"
  | "barcode"
  | "basket-shopping-bag"
  | "bathroom"
  | "bedroom"
  | "bell"
  | "calendar"
  | "call"
  | "campaign-paper"
  | "campaign"
  | "carry-inside"
  | "chat-blog"
  | "check"
  | "checkmark-round"
  | "checkmark"
  | "christmas"
  | "circle-completed"
  | "circle-uncompleted"
  | "clearance-history"
  | "clearance-list-or-campaign"
  | "click-and-collect"
  | "close-thin"
  | "close"
  | "complaint"
  | "counting-archive"
  | "cross"
  | "customer-orders"
  | "customer-service"
  | "danish"
  | "delete"
  | "delivery"
  | "diningroom"
  | "discount-code"
  | "edit-information"
  | "edit-pencil"
  | "edit"
  | "edlp"
  | "email"
  | "error"
  | "external-link"
  | "eye"
  | "facebook"
  | "file-empty"
  | "filter"
  | "flexible-delivery-options"
  | "giftcard-birthday"
  | "globe"
  | "goods-issue"
  | "goods-reception-dc"
  | "goods-reception-history"
  | "goods-reception-other"
  | "heart_fill"
  | "heart"
  | "help"
  | "home"
  | "housewares"
  | "in-stock"
  | "info"
  | "inspiration"
  | "instagram"
  | "jysk-selection"
  | "jysk-splash"
  | "kebab-menu"
  | "linkedin"
  | "livingroom"
  | "login"
  | "marker"
  | "menu"
  | "messenger"
  | "minus-list"
  | "no-internet"
  | "no-results"
  | "nordic"
  | "office"
  | "on-display"
  | "online-banking"
  | "oopus"
  | "open-orders"
  | "order-history"
  | "out-of-stock"
  | "outdoor"
  | "outlet"
  | "payment-method"
  | "permission"
  | "phone"
  | "pinterest"
  | "play-circle"
  | "play"
  | "pricematch"
  | "print"
  | "profile"
  | "question-circle"
  | "receipt"
  | "return"
  | "safe-webshop"
  | "search-error"
  | "search"
  | "sleep-expert-cn"
  | "spinner"
  | "star"
  | "stardeals"
  | "stocktaking-archive"
  | "stocktaking"
  | "storage"
  | "stores"
  | "telegram"
  | "trophy"
  | "twitter"
  | "unlimited-return"
  | "vk"
  | "w3-360-marker"
  | "w3-add"
  | "w3-administration"
  | "w3-arrow-down"
  | "w3-arrow-left"
  | "w3-arrow-right"
  | "w3-arrow-up"
  | "w3-b2b"
  | "w3-bank"
  | "w3-basket"
  | "w3-bell"
  | "w3-calendar"
  | "w3-campaign-paper"
  | "w3-campaign"
  | "w3-chat"
  | "w3-checkmark"
  | "w3-circle-completed"
  | "w3-click-and-collect"
  | "w3-clock"
  | "w3-close"
  | "w3-complaint"
  | "w3-customer-service"
  | "w3-delete"
  | "w3-delivery"
  | "w3-download"
  | "w3-edit"
  | "w3-email"
  | "w3-error"
  | "w3-external-link"
  | "w3-file"
  | "w3-filter"
  | "w3-flag"
  | "w3-flash-off"
  | "w3-flash-on"
  | "w3-giftcard-birthday"
  | "w3-globe"
  | "w3-help"
  | "w3-hide"
  | "w3-home"
  | "w3-image-no-border"
  | "w3-info"
  | "w3-inspiration"
  | "w3-marker"
  | "w3-menu"
  | "w3-on-display"
  | "w3-order-history"
  | "w3-payment-method"
  | "w3-permission"
  | "w3-phone"
  | "w3-play"
  | "w3-preview-giftcard"
  | "w3-profile"
  | "w3-receipt"
  | "w3-return"
  | "w3-safe-webshop"
  | "w3-search"
  | "w3-share"
  | "w3-show"
  | "w3-star-filled"
  | "w3-star"
  | "w3-store-locator"
  | "w3-stores"
  | "w3-success"
  | "w3-tag"
  | "w3-warning"
  | "w3-warranty"
  | "w3-wishlist-filled"
  | "w3-wishlist"
  | "w3-zoom-in"
  | "w3-zoom-out"
  | "warranty"
  | "whatsapp"
  | "window"
  | "wishlist"
  | "youtube"
  | "yuan"
  | "zoom-in"
  | "zoom-out";

export type JyskIconsKey =
  | "i360Marker"
  | "AddStore"
  | "AddToBasket"
  | "AddUser"
  | "Add"
  | "Administration"
  | "Approvedwebshop"
  | "ArrowDown"
  | "ArrowLeft"
  | "ArrowRight"
  | "ArrowUpRound"
  | "ArrowUp"
  | "B2b"
  | "Barcode"
  | "BasketShoppingBag"
  | "Bathroom"
  | "Bedroom"
  | "Bell"
  | "Calendar"
  | "Call"
  | "CampaignPaper"
  | "Campaign"
  | "CarryInside"
  | "ChatBlog"
  | "Check"
  | "CheckmarkRound"
  | "Checkmark"
  | "Christmas"
  | "CircleCompleted"
  | "CircleUncompleted"
  | "ClearanceHistory"
  | "ClearanceListOrCampaign"
  | "ClickAndCollect"
  | "CloseThin"
  | "Close"
  | "Complaint"
  | "CountingArchive"
  | "Cross"
  | "CustomerOrders"
  | "CustomerService"
  | "Danish"
  | "Delete"
  | "Delivery"
  | "Diningroom"
  | "DiscountCode"
  | "EditInformation"
  | "EditPencil"
  | "Edit"
  | "Edlp"
  | "Email"
  | "Error"
  | "ExternalLink"
  | "Eye"
  | "Facebook"
  | "FileEmpty"
  | "Filter"
  | "FlexibleDeliveryOptions"
  | "GiftcardBirthday"
  | "Globe"
  | "GoodsIssue"
  | "GoodsReceptionDc"
  | "GoodsReceptionHistory"
  | "GoodsReceptionOther"
  | "HeartFill"
  | "Heart"
  | "Help"
  | "Home"
  | "Housewares"
  | "InStock"
  | "Info"
  | "Inspiration"
  | "Instagram"
  | "JyskSelection"
  | "JyskSplash"
  | "KebabMenu"
  | "Linkedin"
  | "Livingroom"
  | "Login"
  | "Marker"
  | "Menu"
  | "Messenger"
  | "MinusList"
  | "NoInternet"
  | "NoResults"
  | "Nordic"
  | "Office"
  | "OnDisplay"
  | "OnlineBanking"
  | "Oopus"
  | "OpenOrders"
  | "OrderHistory"
  | "OutOfStock"
  | "Outdoor"
  | "Outlet"
  | "PaymentMethod"
  | "Permission"
  | "Phone"
  | "Pinterest"
  | "PlayCircle"
  | "Play"
  | "Pricematch"
  | "Print"
  | "Profile"
  | "QuestionCircle"
  | "Receipt"
  | "Return"
  | "SafeWebshop"
  | "SearchError"
  | "Search"
  | "SleepExpertCn"
  | "Spinner"
  | "Star"
  | "Stardeals"
  | "StocktakingArchive"
  | "Stocktaking"
  | "Storage"
  | "Stores"
  | "Telegram"
  | "Trophy"
  | "Twitter"
  | "UnlimitedReturn"
  | "Vk"
  | "W3_360Marker"
  | "W3Add"
  | "W3Administration"
  | "W3ArrowDown"
  | "W3ArrowLeft"
  | "W3ArrowRight"
  | "W3ArrowUp"
  | "W3B2b"
  | "W3Bank"
  | "W3Basket"
  | "W3Bell"
  | "W3Calendar"
  | "W3CampaignPaper"
  | "W3Campaign"
  | "W3Chat"
  | "W3Checkmark"
  | "W3CircleCompleted"
  | "W3ClickAndCollect"
  | "W3Clock"
  | "W3Close"
  | "W3Complaint"
  | "W3CustomerService"
  | "W3Delete"
  | "W3Delivery"
  | "W3Download"
  | "W3Edit"
  | "W3Email"
  | "W3Error"
  | "W3ExternalLink"
  | "W3File"
  | "W3Filter"
  | "W3Flag"
  | "W3FlashOff"
  | "W3FlashOn"
  | "W3GiftcardBirthday"
  | "W3Globe"
  | "W3Help"
  | "W3Hide"
  | "W3Home"
  | "W3ImageNoBorder"
  | "W3Info"
  | "W3Inspiration"
  | "W3Marker"
  | "W3Menu"
  | "W3OnDisplay"
  | "W3OrderHistory"
  | "W3PaymentMethod"
  | "W3Permission"
  | "W3Phone"
  | "W3Play"
  | "W3PreviewGiftcard"
  | "W3Profile"
  | "W3Receipt"
  | "W3Return"
  | "W3SafeWebshop"
  | "W3Search"
  | "W3Share"
  | "W3Show"
  | "W3StarFilled"
  | "W3Star"
  | "W3StoreLocator"
  | "W3Stores"
  | "W3Success"
  | "W3Tag"
  | "W3Warning"
  | "W3Warranty"
  | "W3WishlistFilled"
  | "W3Wishlist"
  | "W3ZoomIn"
  | "W3ZoomOut"
  | "Warranty"
  | "Whatsapp"
  | "Window"
  | "Wishlist"
  | "Youtube"
  | "Yuan"
  | "ZoomIn"
  | "ZoomOut";

export enum JyskIcons {
  i360Marker = "360-marker",
  AddStore = "add-store",
  AddToBasket = "add-to-basket",
  AddUser = "add-user",
  Add = "add",
  Administration = "administration",
  Approvedwebshop = "approvedwebshop",
  ArrowDown = "arrow-down",
  ArrowLeft = "arrow-left",
  ArrowRight = "arrow-right",
  ArrowUpRound = "arrow-up-round",
  ArrowUp = "arrow-up",
  B2b = "b2b",
  Barcode = "barcode",
  BasketShoppingBag = "basket-shopping-bag",
  Bathroom = "bathroom",
  Bedroom = "bedroom",
  Bell = "bell",
  Calendar = "calendar",
  Call = "call",
  CampaignPaper = "campaign-paper",
  Campaign = "campaign",
  CarryInside = "carry-inside",
  ChatBlog = "chat-blog",
  Check = "check",
  CheckmarkRound = "checkmark-round",
  Checkmark = "checkmark",
  Christmas = "christmas",
  CircleCompleted = "circle-completed",
  CircleUncompleted = "circle-uncompleted",
  ClearanceHistory = "clearance-history",
  ClearanceListOrCampaign = "clearance-list-or-campaign",
  ClickAndCollect = "click-and-collect",
  CloseThin = "close-thin",
  Close = "close",
  Complaint = "complaint",
  CountingArchive = "counting-archive",
  Cross = "cross",
  CustomerOrders = "customer-orders",
  CustomerService = "customer-service",
  Danish = "danish",
  Delete = "delete",
  Delivery = "delivery",
  Diningroom = "diningroom",
  DiscountCode = "discount-code",
  EditInformation = "edit-information",
  EditPencil = "edit-pencil",
  Edit = "edit",
  Edlp = "edlp",
  Email = "email",
  Error = "error",
  ExternalLink = "external-link",
  Eye = "eye",
  Facebook = "facebook",
  FileEmpty = "file-empty",
  Filter = "filter",
  FlexibleDeliveryOptions = "flexible-delivery-options",
  GiftcardBirthday = "giftcard-birthday",
  Globe = "globe",
  GoodsIssue = "goods-issue",
  GoodsReceptionDc = "goods-reception-dc",
  GoodsReceptionHistory = "goods-reception-history",
  GoodsReceptionOther = "goods-reception-other",
  HeartFill = "heart_fill",
  Heart = "heart",
  Help = "help",
  Home = "home",
  Housewares = "housewares",
  InStock = "in-stock",
  Info = "info",
  Inspiration = "inspiration",
  Instagram = "instagram",
  JyskSelection = "jysk-selection",
  JyskSplash = "jysk-splash",
  KebabMenu = "kebab-menu",
  Linkedin = "linkedin",
  Livingroom = "livingroom",
  Login = "login",
  Marker = "marker",
  Menu = "menu",
  Messenger = "messenger",
  MinusList = "minus-list",
  NoInternet = "no-internet",
  NoResults = "no-results",
  Nordic = "nordic",
  Office = "office",
  OnDisplay = "on-display",
  OnlineBanking = "online-banking",
  Oopus = "oopus",
  OpenOrders = "open-orders",
  OrderHistory = "order-history",
  OutOfStock = "out-of-stock",
  Outdoor = "outdoor",
  Outlet = "outlet",
  PaymentMethod = "payment-method",
  Permission = "permission",
  Phone = "phone",
  Pinterest = "pinterest",
  PlayCircle = "play-circle",
  Play = "play",
  Pricematch = "pricematch",
  Print = "print",
  Profile = "profile",
  QuestionCircle = "question-circle",
  Receipt = "receipt",
  Return = "return",
  SafeWebshop = "safe-webshop",
  SearchError = "search-error",
  Search = "search",
  SleepExpertCn = "sleep-expert-cn",
  Spinner = "spinner",
  Star = "star",
  Stardeals = "stardeals",
  StocktakingArchive = "stocktaking-archive",
  Stocktaking = "stocktaking",
  Storage = "storage",
  Stores = "stores",
  Telegram = "telegram",
  Trophy = "trophy",
  Twitter = "twitter",
  UnlimitedReturn = "unlimited-return",
  Vk = "vk",
  W3_360Marker = "w3-360-marker",
  W3Add = "w3-add",
  W3Administration = "w3-administration",
  W3ArrowDown = "w3-arrow-down",
  W3ArrowLeft = "w3-arrow-left",
  W3ArrowRight = "w3-arrow-right",
  W3ArrowUp = "w3-arrow-up",
  W3B2b = "w3-b2b",
  W3Bank = "w3-bank",
  W3Basket = "w3-basket",
  W3Bell = "w3-bell",
  W3Calendar = "w3-calendar",
  W3CampaignPaper = "w3-campaign-paper",
  W3Campaign = "w3-campaign",
  W3Chat = "w3-chat",
  W3Checkmark = "w3-checkmark",
  W3CircleCompleted = "w3-circle-completed",
  W3ClickAndCollect = "w3-click-and-collect",
  W3Clock = "w3-clock",
  W3Close = "w3-close",
  W3Complaint = "w3-complaint",
  W3CustomerService = "w3-customer-service",
  W3Delete = "w3-delete",
  W3Delivery = "w3-delivery",
  W3Download = "w3-download",
  W3Edit = "w3-edit",
  W3Email = "w3-email",
  W3Error = "w3-error",
  W3ExternalLink = "w3-external-link",
  W3File = "w3-file",
  W3Filter = "w3-filter",
  W3Flag = "w3-flag",
  W3FlashOff = "w3-flash-off",
  W3FlashOn = "w3-flash-on",
  W3GiftcardBirthday = "w3-giftcard-birthday",
  W3Globe = "w3-globe",
  W3Help = "w3-help",
  W3Hide = "w3-hide",
  W3Home = "w3-home",
  W3ImageNoBorder = "w3-image-no-border",
  W3Info = "w3-info",
  W3Inspiration = "w3-inspiration",
  W3Marker = "w3-marker",
  W3Menu = "w3-menu",
  W3OnDisplay = "w3-on-display",
  W3OrderHistory = "w3-order-history",
  W3PaymentMethod = "w3-payment-method",
  W3Permission = "w3-permission",
  W3Phone = "w3-phone",
  W3Play = "w3-play",
  W3PreviewGiftcard = "w3-preview-giftcard",
  W3Profile = "w3-profile",
  W3Receipt = "w3-receipt",
  W3Return = "w3-return",
  W3SafeWebshop = "w3-safe-webshop",
  W3Search = "w3-search",
  W3Share = "w3-share",
  W3Show = "w3-show",
  W3StarFilled = "w3-star-filled",
  W3Star = "w3-star",
  W3StoreLocator = "w3-store-locator",
  W3Stores = "w3-stores",
  W3Success = "w3-success",
  W3Tag = "w3-tag",
  W3Warning = "w3-warning",
  W3Warranty = "w3-warranty",
  W3WishlistFilled = "w3-wishlist-filled",
  W3Wishlist = "w3-wishlist",
  W3ZoomIn = "w3-zoom-in",
  W3ZoomOut = "w3-zoom-out",
  Warranty = "warranty",
  Whatsapp = "whatsapp",
  Window = "window",
  Wishlist = "wishlist",
  Youtube = "youtube",
  Yuan = "yuan",
  ZoomIn = "zoom-in",
  ZoomOut = "zoom-out",
}

export const JYSK_ICONS_CODEPOINTS: { [key in JyskIcons]: string } = {
  [JyskIcons.i360Marker]: "61697",
  [JyskIcons.AddStore]: "61698",
  [JyskIcons.AddToBasket]: "61699",
  [JyskIcons.AddUser]: "61700",
  [JyskIcons.Add]: "61701",
  [JyskIcons.Administration]: "61702",
  [JyskIcons.Approvedwebshop]: "61703",
  [JyskIcons.ArrowDown]: "61704",
  [JyskIcons.ArrowLeft]: "61705",
  [JyskIcons.ArrowRight]: "61706",
  [JyskIcons.ArrowUpRound]: "61707",
  [JyskIcons.ArrowUp]: "61708",
  [JyskIcons.B2b]: "61709",
  [JyskIcons.Barcode]: "61710",
  [JyskIcons.BasketShoppingBag]: "61711",
  [JyskIcons.Bathroom]: "61712",
  [JyskIcons.Bedroom]: "61713",
  [JyskIcons.Bell]: "61714",
  [JyskIcons.Calendar]: "61715",
  [JyskIcons.Call]: "61716",
  [JyskIcons.CampaignPaper]: "61717",
  [JyskIcons.Campaign]: "61718",
  [JyskIcons.CarryInside]: "61719",
  [JyskIcons.ChatBlog]: "61720",
  [JyskIcons.Check]: "61721",
  [JyskIcons.CheckmarkRound]: "61722",
  [JyskIcons.Checkmark]: "61723",
  [JyskIcons.Christmas]: "61724",
  [JyskIcons.CircleCompleted]: "61725",
  [JyskIcons.CircleUncompleted]: "61726",
  [JyskIcons.ClearanceHistory]: "61727",
  [JyskIcons.ClearanceListOrCampaign]: "61728",
  [JyskIcons.ClickAndCollect]: "61729",
  [JyskIcons.CloseThin]: "61730",
  [JyskIcons.Close]: "61731",
  [JyskIcons.Complaint]: "61732",
  [JyskIcons.CountingArchive]: "61733",
  [JyskIcons.Cross]: "61734",
  [JyskIcons.CustomerOrders]: "61735",
  [JyskIcons.CustomerService]: "61736",
  [JyskIcons.Danish]: "61737",
  [JyskIcons.Delete]: "61738",
  [JyskIcons.Delivery]: "61739",
  [JyskIcons.Diningroom]: "61740",
  [JyskIcons.DiscountCode]: "61741",
  [JyskIcons.EditInformation]: "61742",
  [JyskIcons.EditPencil]: "61743",
  [JyskIcons.Edit]: "61744",
  [JyskIcons.Edlp]: "61745",
  [JyskIcons.Email]: "61746",
  [JyskIcons.Error]: "61747",
  [JyskIcons.ExternalLink]: "61748",
  [JyskIcons.Eye]: "61749",
  [JyskIcons.Facebook]: "61750",
  [JyskIcons.FileEmpty]: "61751",
  [JyskIcons.Filter]: "61752",
  [JyskIcons.FlexibleDeliveryOptions]: "61753",
  [JyskIcons.GiftcardBirthday]: "61754",
  [JyskIcons.Globe]: "61755",
  [JyskIcons.GoodsIssue]: "61756",
  [JyskIcons.GoodsReceptionDc]: "61757",
  [JyskIcons.GoodsReceptionHistory]: "61758",
  [JyskIcons.GoodsReceptionOther]: "61759",
  [JyskIcons.HeartFill]: "61760",
  [JyskIcons.Heart]: "61761",
  [JyskIcons.Help]: "61762",
  [JyskIcons.Home]: "61763",
  [JyskIcons.Housewares]: "61764",
  [JyskIcons.InStock]: "61765",
  [JyskIcons.Info]: "61766",
  [JyskIcons.Inspiration]: "61767",
  [JyskIcons.Instagram]: "61768",
  [JyskIcons.JyskSelection]: "61769",
  [JyskIcons.JyskSplash]: "61770",
  [JyskIcons.KebabMenu]: "61771",
  [JyskIcons.Linkedin]: "61772",
  [JyskIcons.Livingroom]: "61773",
  [JyskIcons.Login]: "61774",
  [JyskIcons.Marker]: "61775",
  [JyskIcons.Menu]: "61776",
  [JyskIcons.Messenger]: "61777",
  [JyskIcons.MinusList]: "61778",
  [JyskIcons.NoInternet]: "61779",
  [JyskIcons.NoResults]: "61780",
  [JyskIcons.Nordic]: "61781",
  [JyskIcons.Office]: "61782",
  [JyskIcons.OnDisplay]: "61783",
  [JyskIcons.OnlineBanking]: "61784",
  [JyskIcons.Oopus]: "61785",
  [JyskIcons.OpenOrders]: "61786",
  [JyskIcons.OrderHistory]: "61787",
  [JyskIcons.OutOfStock]: "61788",
  [JyskIcons.Outdoor]: "61789",
  [JyskIcons.Outlet]: "61790",
  [JyskIcons.PaymentMethod]: "61791",
  [JyskIcons.Permission]: "61792",
  [JyskIcons.Phone]: "61793",
  [JyskIcons.Pinterest]: "61794",
  [JyskIcons.PlayCircle]: "61795",
  [JyskIcons.Play]: "61796",
  [JyskIcons.Pricematch]: "61797",
  [JyskIcons.Print]: "61798",
  [JyskIcons.Profile]: "61799",
  [JyskIcons.QuestionCircle]: "61800",
  [JyskIcons.Receipt]: "61801",
  [JyskIcons.Return]: "61802",
  [JyskIcons.SafeWebshop]: "61803",
  [JyskIcons.SearchError]: "61804",
  [JyskIcons.Search]: "61805",
  [JyskIcons.SleepExpertCn]: "61806",
  [JyskIcons.Spinner]: "61807",
  [JyskIcons.Star]: "61808",
  [JyskIcons.Stardeals]: "61809",
  [JyskIcons.StocktakingArchive]: "61810",
  [JyskIcons.Stocktaking]: "61811",
  [JyskIcons.Storage]: "61812",
  [JyskIcons.Stores]: "61813",
  [JyskIcons.Telegram]: "61814",
  [JyskIcons.Trophy]: "61815",
  [JyskIcons.Twitter]: "61816",
  [JyskIcons.UnlimitedReturn]: "61817",
  [JyskIcons.Vk]: "61818",
  [JyskIcons.W3_360Marker]: "61819",
  [JyskIcons.W3Add]: "61820",
  [JyskIcons.W3Administration]: "61821",
  [JyskIcons.W3ArrowDown]: "61822",
  [JyskIcons.W3ArrowLeft]: "61823",
  [JyskIcons.W3ArrowRight]: "61824",
  [JyskIcons.W3ArrowUp]: "61825",
  [JyskIcons.W3B2b]: "61826",
  [JyskIcons.W3Bank]: "61827",
  [JyskIcons.W3Basket]: "61828",
  [JyskIcons.W3Bell]: "61829",
  [JyskIcons.W3Calendar]: "61830",
  [JyskIcons.W3CampaignPaper]: "61831",
  [JyskIcons.W3Campaign]: "61832",
  [JyskIcons.W3Chat]: "61833",
  [JyskIcons.W3Checkmark]: "61834",
  [JyskIcons.W3CircleCompleted]: "61835",
  [JyskIcons.W3ClickAndCollect]: "61836",
  [JyskIcons.W3Clock]: "61837",
  [JyskIcons.W3Close]: "61838",
  [JyskIcons.W3Complaint]: "61839",
  [JyskIcons.W3CustomerService]: "61840",
  [JyskIcons.W3Delete]: "61841",
  [JyskIcons.W3Delivery]: "61842",
  [JyskIcons.W3Download]: "61843",
  [JyskIcons.W3Edit]: "61844",
  [JyskIcons.W3Email]: "61845",
  [JyskIcons.W3Error]: "61846",
  [JyskIcons.W3ExternalLink]: "61847",
  [JyskIcons.W3File]: "61848",
  [JyskIcons.W3Filter]: "61849",
  [JyskIcons.W3Flag]: "61850",
  [JyskIcons.W3FlashOff]: "61851",
  [JyskIcons.W3FlashOn]: "61852",
  [JyskIcons.W3GiftcardBirthday]: "61853",
  [JyskIcons.W3Globe]: "61854",
  [JyskIcons.W3Help]: "61855",
  [JyskIcons.W3Hide]: "61856",
  [JyskIcons.W3Home]: "61857",
  [JyskIcons.W3ImageNoBorder]: "61858",
  [JyskIcons.W3Info]: "61859",
  [JyskIcons.W3Inspiration]: "61860",
  [JyskIcons.W3Marker]: "61861",
  [JyskIcons.W3Menu]: "61862",
  [JyskIcons.W3OnDisplay]: "61863",
  [JyskIcons.W3OrderHistory]: "61864",
  [JyskIcons.W3PaymentMethod]: "61865",
  [JyskIcons.W3Permission]: "61866",
  [JyskIcons.W3Phone]: "61867",
  [JyskIcons.W3Play]: "61868",
  [JyskIcons.W3PreviewGiftcard]: "61869",
  [JyskIcons.W3Profile]: "61870",
  [JyskIcons.W3Receipt]: "61871",
  [JyskIcons.W3Return]: "61872",
  [JyskIcons.W3SafeWebshop]: "61873",
  [JyskIcons.W3Search]: "61874",
  [JyskIcons.W3Share]: "61875",
  [JyskIcons.W3Show]: "61876",
  [JyskIcons.W3StarFilled]: "61877",
  [JyskIcons.W3Star]: "61878",
  [JyskIcons.W3StoreLocator]: "61879",
  [JyskIcons.W3Stores]: "61880",
  [JyskIcons.W3Success]: "61881",
  [JyskIcons.W3Tag]: "61882",
  [JyskIcons.W3Warning]: "61883",
  [JyskIcons.W3Warranty]: "61884",
  [JyskIcons.W3WishlistFilled]: "61885",
  [JyskIcons.W3Wishlist]: "61886",
  [JyskIcons.W3ZoomIn]: "61887",
  [JyskIcons.W3ZoomOut]: "61888",
  [JyskIcons.Warranty]: "61889",
  [JyskIcons.Whatsapp]: "61890",
  [JyskIcons.Window]: "61891",
  [JyskIcons.Wishlist]: "61892",
  [JyskIcons.Youtube]: "61893",
  [JyskIcons.Yuan]: "61894",
  [JyskIcons.ZoomIn]: "61895",
  [JyskIcons.ZoomOut]: "61896",
};
