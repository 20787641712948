import { Icon, IconName, IconSize } from '@wla/components/ui/icon';
import { cn } from '@wla/lib/helpers/cn';
import { cva, type VariantProps } from 'class-variance-authority';
import { ButtonHTMLAttributes, ElementType, createElement, forwardRef } from 'react';

export type ButtonVariantProps = VariantProps<typeof buttonVariants>;

export const buttonVariants = cva(
  'form-input inline-flex items-center justify-center text-base font-semibold rounded border border-transparent cursor-pointer focus:ring-0 disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        primary: 'bg-blue-500 text-white disabled:bg-gray-400',
        secondary: 'border border-black disabled:border-gray-400 disabled:text-gray-400 bg-transparent',
      },
      size: {
        large: 'h-14 py-2.5 px-4 md:px-5 gap-x-2 md:gap-x-1',
        medium: 'h-11 py-2.5 px-4 gap-x-2',
      },
      iconPosition: {
        left: 'flex-row',
        right: 'flex-row-reverse',
        only: '',
      },
    },
    compoundVariants: [
      {
        variant: ['primary', 'secondary'],
        size: 'large',
        iconPosition: 'left',
        className: 'pl-4',
      },
      {
        variant: ['primary', 'secondary'],
        size: 'large',
        iconPosition: 'right',
        className: 'pr-4.5',
      },
      {
        variant: ['primary', 'secondary'],
        size: 'large',
        iconPosition: 'only',
        className: 'p-[11px] w-14',
      },
      {
        variant: ['primary', 'secondary'],
        size: 'medium',
        iconPosition: 'left',
        className: 'pl-2.5',
      },
      {
        variant: ['primary', 'secondary'],
        size: 'medium',
        iconPosition: 'right',
        className: 'pr-2.5',
      },
      {
        variant: ['primary', 'secondary'],
        size: 'medium',
        iconPosition: 'only',
        className: 'p-[7px] w-11',
      },
      {
        variant: ['primary'],
        size: ['large', 'medium'],
        className: 'hover:ease-out hover:duration-300 hover:bg-blue-600',
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'large',
    },
  },
);

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonVariantProps &
  (
    | {
        as?: ElementType;
      }
    | {
        as: 'a';
        href: string;
        target?: string;
      }
  ) & {
    iconSize?: IconSize;
    iconWeight?: 'font-thin' | 'font-normal' | 'font-bold';
    customIcon?: IconName;
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      as = 'button',
      className,
      variant,
      size,
      iconPosition,
      iconSize = '20',
      iconWeight = 'font-bold',
      children,
      customIcon,
      ...props
    },
    ref,
  ) => {
    return createElement(
      as,
      {
        ...props,
        className: cn(buttonVariants({ variant, size, iconPosition, className }), {
          'pl-2.5 pr-4 md:px-5': iconPosition === 'left',
        }),
        ref,
      },
      <>
        {iconPosition && <Icon src={customIcon ?? 'w3-arrow-right'} size={iconSize} className={iconWeight} />}
        {iconPosition !== 'only' && children}
      </>,
    );
  },
);
Button.displayName = 'Button';
